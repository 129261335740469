import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { EDF_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import Testimonial from '@/components/Testimonial';
import NextProject from '@/components/Portfolio/NextProject';
import DoublePictures from '@/components/Portfolio/DoublePictures';

import testimonial from '@/referentials/testimonials/artelink';

export default function Artelink({ data }) {
  const { t } = useTranslation();

  const tags = ['ux', 'development', 'api', 'growth', 'data'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverArtelink')} />

      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <DoublePictures
        pictures={[
          {
            source: data.leftImage,
            alt: 'Logo Artelink',
          },
          {
            source: data.rightImage,
            alt: 'Livre blanc',
          },
        ]}
      />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />

      <Testimonial testimonial={testimonial()} />

      <NextProject title={t('nextProject')} to={EDF_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["artelink", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/artelink/artelink.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    leftImage: file(
      relativePath: { eq: "projects/artelink/logo-artelink.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    rightImage: file(
      relativePath: { eq: "projects/artelink/livre-blanc.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
